/* Updated header styles */
.App-header {
  background-color: #282c34;  /* Dark background */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px; /* Reasonable height for header */
  color: white;
}

.App-title {
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
}

.App-footer {
  background-color: #282c34;
  color: white;
  text-align: right;
  padding: 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.Admin-login {
  color: white;
  text-decoration: none;
}